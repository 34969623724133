@media (min-width:768px) and (max-width:1199px){
  .uniqe_editor>div {
    width: calc(100%);
    max-width: 50%;
}
}
@media screen and (max-width:767px){
  .uniqe_editor {
    display: flex;
    flex-wrap: wrap;
    }
  .WriteChat_inner_box .chat_lists .message .avatar {
    width: 100%;
    max-width: 100%;
  }
  .WriteChat_inner_box .chat_lists .message .content {
    width: 100% !important;
    max-width: 100%;
    font-size: 17px;
    padding: 10px;
    text-align: left;
    font-family: "Merriweather", serif !important;
  
  }
  .uniqe_editor>div {
    width: calc(100%);
    max-width: 100%;
}
}
.after_save_outer.final_score_popup {
  max-width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
}
.after_save_outer.final_score_popup .pop-up-warning-container {
  max-width: 800px;
  min-height: 100px;
  left: 50%;
  height: auto;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 90vh;
  z-index: 9;
  overflow: auto;
}

.final_score_popup .pop_heading h2 {
  font-size: 31px;
  font-weight: 700 !important;
  color: #3f51b5;
  margin-bottom: 20px;
  text-align: center;
}
.score_list h3 {
  font-size: 16px;
  text-align: left;
  margin: 0;
  width: 100%;
  color: #3f51b5;
}
.pop-up-warning-container div.score_list:nth-child(1) {
  margin-bottom: 0;
}
.final_score_popup .pop-up-warning-container div button.custom_btn{
  max-width: inherit !important;
  width: fit-content !important;
}
.score_list p {
  font-size: 16px;
  text-align: left;
  margin: 0;
  width: 100%;
}
.score_list{
  border-bottom: 1px solid #ccc;
  padding: 0 0 10px ;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.top_heading h2 {
  color: rgba(0, 0, 0, 0.75);
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.page_outer {
  padding: 100px 30px 0;
  box-sizing: border-box;
}
.inner_box {
  background: #fff;
  padding: 30px;
  width: 100%;
}
.top_heading h2 {
  text-align: left;
}

.top_heading h2::after {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  position: absolute;
  height: 1px;
  top: 20px;
}
.top_heading h2 span {
  background: #fff;
  position: relative;
  z-index: 1;
  padding-right: 25px;
}
.list_tick {
  display: flex;
  align-items: last baseline;
  color: rgba(0, 0, 0, 0.75);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  justify-content: space-between;
}
.question_list {
  width: 100%;
  margin-bottom: 22px;
  text-align: left;
  min-height: 26px;
}
.question_list input {
  height: 24px !important;
  padding: 0px !important;
  border: none !important;
  border-bottom: 2px dashed #000 !important;
  color: rgba(0, 0, 0, 0.75);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  outline: none !important;
  line-height: 1 !important;
  background-color: #fff;
}
.question_list [data-testid="CancelIcon"] {
  fill: #ff0000;
}
.question_list [data-testid="CheckCircleIcon"] {
  fill: #00c200;
}
.custom_btn {
  border-radius: 4.927px !important;
  /* background: #3f51b5 !important ; */
  width: 100% !important;
  max-width: 319.304px !important;
  height: 73.91px !important;
  flex-shrink: 0 !important;
  color: #fff !important;
  font-family: Poppins !important;
  font-size: 23.651px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: uppercase !important;
  border : none
}
.question_btn_list {
  margin-top: 20px;
  gap: 25px;
  justify-content: flex-start !important;
  margin-top: 45px !important;
  max-width: calc(100% - 50px);
}
.after_save_outer {
  padding-top: 30px;
}
.after_save_outer {
  margin-top: 30px !important;
  color: rgba(0, 0, 0, 0.75);
  font-family: Poppins;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: -moz-fit-content;
  width: fit-content;
  padding: 25px;
  margin: 0 auto;
  border-radius: 10px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  left: 0;
}
.after_save_outer button {
  border-radius: 4.927px !important;
  background: #3f51b5 !important;
  width: 100% !important;
  max-width: 150px !important;
  height: 52px !important;
  flex-shrink: 0 !important;
  color: #fff !important;
  font-family: Poppins !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: uppercase !important;
  border: none !important;
  margin: 10px;
}

.after_save_outer div {
  margin: 5px 0;
}
.ques_score.after_save_outer {
  min-width: 310px;
}
.ques_score.after_save_outer p {
  margin: 3px 0;
  font-size: 20px;
}
.question_list h3 {
  color: rgba(0, 0, 0, 0.75);
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 20px;
}
.question_list h6 {
  color: rgba(0, 0, 0, 0.75);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.question_option label {
  color: rgba(0, 0, 0, 0.75);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.question_mark {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 12px;
  width: 100%;
}
.question_option [type="checkbox"],
.question_option [type="radio"] {
  width: 30px;
  height: 30px;
  accent-color: #000;
}
.top_heading h3 {
  color: rgba(0, 0, 0, 0.75);
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  text-align: left;
  margin: 0 0 20px;
}
.label_tag {
  color: rgba(0, 0, 0, 0.75);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  margin: 0 0 10px;
}
.input_box {
  margin-bottom: 15px;
}
.input_box input {
  border-radius: 10px;
  border: 2px solid #dedede;
  height: 73px;
  color: rgba(0, 0, 0, 0.5);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 20px 30px;
  width: 100%;
  outline: none !important;
  box-shadow: none !important;
}


.spinner-container{
  width: 100%;
  height: 100%;
  min-height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* chat loading icon  */
.spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #3498db;
  border-left-color: #3498db;
  animation: spin 1s ease-in-out infinite;
  position: absolute;
  left: 43%;
  bottom: 33%;
}

.spinner2 {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #3498db;
  border-left-color: #3498db;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.chat_lists {
  height: 60vh;
  max-height: 600px;
  width: 100%;
}
.chat-input {
  border-radius: var(--radi-6, 12px);
  border: 2px solid #e0e0e0;
  background: #f9fafa;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat_lists .chat-messages {
  overflow-y: auto;
  height: calc(100% - 104px);
  width: 100%;
}
.chat-input input {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  color: var(--light-theme-rest-foreground-default-foreground-1424242, #424242);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.455px;
  width: 100%;
  padding: 20px 26px;
  height: 100px;
}
button.btn {
  background: transparent !important;
  outline: none !important;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  border-radius: 10px;
  border: 1px solid #ddd;
  background: #fff;
  box-shadow: 0px 0.191px 0.573px 0px rgba(0, 0, 0, 0.11),
    0px 1.018px 2.291px 0px rgba(0, 0, 0, 0.13);
  display: flex;
  width: fit-content;
  justify-content: flex-end;
  align-items: center;
  gap: 19px;
  color: rgba(41, 41, 41, 0.8);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  flex-shrink: 0;
  padding: 19px;
  text-align: justify;
  flex-wrap: wrap;
  max-width: 50%;
  position: relative;
}
/* .content::after {
  left: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  content: "";
  background: #082567;
  width: 6px;
  border-radius: 10px 0 0 10px;
} */

/* .message.bot .content::after {
  left: auto;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  content: "";
  background: #02040f;
  width: 6px;
  border-radius: 0 10px 10px 0;
} */

.message.user,
.message.bot {
  display: flex;
  gap: 15px;
  align-items: flex-end;
  margin-bottom: 12px;
}
.message.bot {
  justify-content: flex-end;
  margin-right: 8px;
}
.message.bot .avatar {
  order: 2;
}
.message.bot .content {
  order: 1;
}
.message.bot .content {
  max-width: 40%;
  text-align: justify;
}
.message.user .avatar {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  overflow: hidden;
}
.message.user .avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.index_num {
  color: rgba(0, 0, 0, 0.75);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin: 0 0 10px;
  cursor: pointer;
}

.activity_name {
  color: rgba(0, 0, 0, 0.75);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  text-align: left;
  margin: 20px 0 20px;
  display: block;
  width: 100%;
  cursor: pointer;
}
.writing_editor span.jodit-placeholder {
  color: rgba(0, 0, 0, 0.75);
  font-family: Poppins;
  text-align: left !important;
}
.ourse_creator_outer .MuiBox-root {
  border-radius: 28px;
  background: #fff;
}
.ourse_creator_outer h2.page_label {
  color: #000;
  font-family: Poppins;
  font-size: 21.823px !important;
  font-style: normal;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}
.custom_btn_style {
  width: 100%;
  max-width: 162px;
  height: 52px;
  color: rgb(255, 255, 255);
  font-family: Poppins;
  border-radius: 10px !important;
  background: rgb(63, 81, 181);
  border-width: initial !important;
  border-style: none !important;
  border-color: initial !important;
  border-image: initial !important;
  outline: none !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  letter-spacing: 0 !important;
}


/*----new-css---*/
h2.course_headeing {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-align: left;
  color: #000;
 
}
span.subject_title {
  color: #565656;
  margin-right: 5px;
  font-weight: 500;
  font-size: 18px;
}
.ourse_creator_inner .card_box p, .topics_gener_main .card_box p  {
  font-weight: 600 !important;
  font-size: 17px !important;
}
span.value_course {
  color: #565656;
  font-size: 17px;
  font-weight: 400 !important;
}

.ourse_creator_inner .MuiGrid-item .FlexRow {
  display: flex;
flex-direction: row !important;
}

.ourse_creator_inner .MuiGrid-item .FlexItem {
  display: flex;
  justify-content: space-between !important;
}
.lesson_plane_main .content_outer div {
  text-align: left;
 
}

.lesson_plane_main .content_outer h6 {
  font-size: 24px !important;
  color: #343131;
}
.lesson_plane_main .content_outer p {
  font-size: 18px;
  font-weight: 600 !important;
}
.lesson_plane_main .content_outer p {
  margin-bottom: 10px !important;
}
.activity_box>div {
  width: 100% !important;
  max-width: 48%;
  padding: 5px;
}
.lesson_plane_main .activity_box {
  display: flex;
flex-wrap:wrap;
gap:10px;
}
.activity_box>div {
  width: 100% !important;
  max-width: 48%;
  padding: 10px;
  border-radius: 5px;
  background-color: #889bff;
  color: #fff;
}
.button-box-outer .FlexItem {
  display: flex;
  justify-content: space-around;
}





.card_box p,
.inner_desc h6,
.inner_desc div {
  color: #000;
  font-family: Poppins !important;
  font-size: 18.572px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 44.572px !important;
  text-transform: uppercase;
  margin: 0;
  text-align: left;
}
.card_info {
  margin: 0 !important;
}
.ourse_creator_outer .card_info {
  padding: 0 !important;
}
.ourse_creator_outer .ourse_creator_inner {
  padding: 30px 30px 50px;
}
.card_row_outer {
  margin: 0 !important;
}
.top_sec_outer {
  align-items: center;
}
.ourse_creator_outer .inner_row_sec {
  padding: 0 15px 10px;
}
.ourse_creator_outer .ourse_creator_inner.no_bg {
  background: transparent;
  padding: 0;
  margin-top: -20px;
}
.inner_desc {
  margin-bottom: -15px;
}
.sub_topic_btn div {
  padding: 0;
  margin: 0;
}
.sub_topic_btn button {
  margin-bottom: 10px;
}
.topic_label {
  color: rgba(0, 0, 0, 0.75);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  text-align: left;
  cursor: pointer;
}
.chat_topic.role_type h3 {
  color: rgba(0, 0, 0, 0.75);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
}
.chat_main {
  display: flex;
  width: 100%;
  align-items: flex-start;
  height: 100%;
  gap: 70px;
}
.btn_actions {
  border-radius: 10px;
  background: #f9fafa;
  padding: 20px;
  width: 100%;
  max-width: 302px;
  height: 592px;
}
.btn_actions button {
  outline: none !important;
  border-radius: 6px;
  border: 0.5px solid #ddd;
  background: #f8fafa;
  box-shadow: none !important;
  width: 100%;
  display: flex;
  max-width: 262px;
  height: 55px;
  padding: 12px 70px 13px 27px;
  align-items: center;
  flex-shrink: 0;
  color: rgba(0, 0, 0, 0.75);
  text-align: right;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  margin: 0 0 14px;
  line-height: normal;
  cursor: pointer;
}
.chat_side {
  width: 100%;
}
.page_outer.course_lesson {
  padding-top: 10px;
}
.check_box_from {
  border-radius: 5px;
  background: #f5f5f5;
  width: 100%;
  height: 75px;
  position: relative;
  margin: 0 !important;
}
.check_box_list {
  color: #000;
  font-family: Poppins !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  text-transform: capitalize;
  width: 100%;
  text-align: center;
  justify-content: center;
}
.check_box_from .MuiCheckbox-root,
.check_box_from .MuiCheckbox-root .MuiIconButton-label {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.check_box_from .MuiCheckbox-root .MuiIconButton-label svg {
  opacity: 0;
}
.check_box_from span.MuiTypography-root {
  width: 100%;
  text-align: center;
}
.check_box_from:hover .MuiCheckbox-colorSecondary.Mui-checked,
.check_box_from .MuiCheckbox-colorSecondary.Mui-checked:hover,
.check_box_from span.MuiButtonBase-root.check_btn.Mui-checked,
.check_btn.Mui-checked {
  opacity: 1 !important;
  border-radius: 5px;
  background: transparent !important;
  padding: 0 !important;
  box-shadow: none !important;
}
.select .check_box_list {
  color: #fff;
}
.check_box_from .MuiIconButton-colorSecondary:hover {
  background-color: #f5f5f5 !important;
  border-radius: 0 !important;
}
.check_box_from .MuiIconButton-colorSecondary {
  border-radius: 0 !important;
}
.check_box_from.select {
  background: #3f51b5;
}
.custom_btn.lesson_btn {
  margin-top: 30px;
}
.lesson_planner_outer .inner_box {
  border-radius: 28px;
}
.page_outer.lesson_planner_outer {
  margin-top: -180px;
}
.page_outer.lesson_planner_outer h2,
.page_outer.lesson_planner_outer h3 {
  color: var(--Color-Neutral-black, #000);
  font-family: Poppins;
  font-size: 31.217px;
  font-style: normal;
  font-weight: 600;
  line-height: 51.093px;
  text-transform: capitalize;
}
.page_outer.lesson_planner_outer h3 {
  font-size: 24px;
}
.page_outer.lesson_planner_outer ul {
  list-style: none;
  margin: 0;
}
.page_outer.lesson_planner_outer ul li {
  list-style: none;
}
.bottom_btn {
  width: 100%;
}
.page_outer.course_lesson .question_btn_list {
  width: 100% !important;
}
.page_outer.course_lesson .btn_wrp {
  width: 100%;
}
.page_outer.role_pay_outer {
  padding-bottom: 40px;
}
.page_outer.gist_page .inner_fild {
  position: relative;
  padding-right: 0;
  width: 100%;
}
.inner_div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.page_outer.gist_page .inner_fild {
  display: flex;
  align-items: last baseline;
}
.page_outer.gist_page .inner_fild span {
  margin-left: 10px;
}
.in_listing select {
  border-radius: 10px;
  border: 2px solid #dedede;
  height: 73px;
  color: rgba(0, 0, 0, 0.5);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 30px;
  width: 100%;
  outline: none !important;
  box-shadow: none !important;
  background: transparent;
}
.main_listings.input_box {
  display: flex;
  align-items: flex-end;
  width: 100%;
  gap: 15px;
}
.main_listings.input_box .in_listing {
  width: 100%;
}
.main_listings.input_box [data-testid="CancelIcon"] {
  fill: #ff0000;
}
.main_listings.input_box [data-testid="CheckCircleIcon"] {
  fill: #00c200;
}
.main_listings.input_box span {
  position: relative;
  top: -7px;
}




@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .main_listings.input_box span {
    top: -10px;
  }
  .in_listing select {
    height: 50px;
    font-size: 15px;
    padding: 0 15px;
  }
  .ourse_creator_outer .card_info button {
    font-size: 14px;
  }
  .top_sec_outer button {
    font-size: 14px !important;
    width: fit-content;
    height: 45px !important;
    padding: 0 12px !important;
  }
  .card_box p,
  .inner_desc h6,
  .inner_desc div {
    font-size: 16px !important;
    line-height: 36px !important;
  }
  .card_row_outer .sub_topic_btn {
    padding: 0;
  }
  .ourse_creator_outer .inner_row_sec {
    padding: 0 0 10px;
  }
  .custom_btn {
    max-width: fit-content !important;
    height: 45px !important;
    font-size: 14px !important;
    padding: 0 25px !important;
  }
  .list_side {
    width: 100%;
    text-align: left;
  }
  .list_tick {
    font-size: 16px;
  }
  .after_save_outer {
    font-size: 17px;
    padding: 25px;
    width: 100%;
    max-width: 460px;
  }
  .after_save_outer button {
    max-width: 150px !important;
    height: 45px !important;
    font-size: 16px !important;
  }

  .chat-input input {
    font-size: 18px;
    height: 70px;
  }
  button.btn {
    height: 70px;
  }
  .chat-input {
    height: 74px;
  }
  .btn_actions button {
    height: 49px;
    font-size: 16px;
  }
  .question_list input {
    font-size: 16px;
  }
  .label_tag {
    font-size: 18px;
  }
  .input_box input {
    height: 55px;
    font-size: 16px;
    padding: 15px;
  }
  .check_box_list {
    font-size: 20px;
  }

}

@media only screen and (min-width: 1200px) {
  .role_pay_outer .chat_lists .chat-messages {
    height: 100%;
  }
  .role_pay_outer .chat_lists {
    height: 60vh;
  }

  .card_row_outer {
    padding: 15px;
  }
  .col_sec_outer {
    margin: 0 -15px;
  }
  .card_row_outer {
    max-width: 100%;
    margin: 0 !important;
  }
  .chat_lists {
    height: 80vh;
    max-height: 700px;
    width: 100%;
  }
  .donoo {
    padding: 50px;
} 
.donoo .makeStyles-ButtonGroup-9 {
  width: 100% !important;
  justify-content: space-between !important;
}
.donoo>div:nth-child(2) {
  padding: 50px;
} 
.gap_fill_main .page_outer {
  padding: 150px;
}
.cust_inner_box_gap {
  padding: 50px;
}
.page_outer.page_outer_quiz>div {
  padding: 50px;
}
.page_outer_quiz {
  padding: 150px;
}
.defination_mathch_main .page_outer {
  padding: 150px;
  }
  .defination_mathch_main .page_outer .inner_box {
  padding: 50px;
  }
  .writing_outer{
    padding: 150px;
  }
  .writing_outer .inner_box{
    padding:50px;
  }
  .gist_page{
    padding: 150px;
  }
  .gist_page .inner_box{
    padding:50px;
  }
  .role_pay_outer{
    padding: 150px;
  }
  .role_pay_outer .inner_box{
    padding:50px;
  }
  .debate_outer {
    padding: 150px !important;
    }
    .debate_outer>div {
    padding: 50px;
    }
    .case_main .page_outer {
      padding: 150px !important;
  }
  .case_main .page_outer>div{
    padding:50px;
  }
  .ourse_creator_outer {
    padding: 63px 150px !important;
}
.topics_gener_main .ourse_creator_outer {
  padding: 30px !important;
}
/* header {
  position: relative !important;
  background: #889bff !important;
  padding: 60px 150px 0px 150px;
} */
.reading-main-ac{
  padding:100px 0px !important;
}
.reading-main-ac div:nth-of-type(2) {
  width: 100%;
  background: none;
  padding:0px 150px;
}
}

@media only screen and (max-width: 767px) {
  .final_score_popup button.custom_btn {
    font-size: 14px !important;
    margin: 0 5px  !important;
    padding: 8px 20px !important;
    height: 45px !important;
}
  .page_outer {
    padding: 80px 0px 0;
  }
  .inner_box {
    padding: 30px 15px;
  }
  .list_tick {
    font-size: 16px;
    gap: 15px;
  }
  .list_side {
    text-align: left;
  }
  .question_list input {
    max-width: 140px;
    font-size: 16px !important;
  }
  .custom_btn {
    height: 50px !important;
    font-size: 16px !important;
    margin: 0 !important;
    max-width: 100% !important;
    letter-spacing: 0 !important;
  }
  .question_btn_list {
    gap: 10px !important;
  }
  .top_heading h2 {
    font-size: 22px;
  }
  .top_heading h2 span {
    padding-right: 15px;
  }
  .input_box input {
    height: 55px;
    font-size: 16px;
    padding: 15px;
  }
  .label_tag {
    font-size: 18px;
  }
  .top_heading h3 {
    font-size: 20px;
  }
  .chat-input input {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    padding: 15px;
    height: 60px;
  }
  .chat-input {
    height: 64px;
  }
  button.btn {
    height: 60px;
    padding: 0 10px 0;
    box-shadow: none !important;
  }
  .content,
  .message.bot .content {
    max-width: calc(100% - 60px);
    font-size: 14px;
  }
  .chat_lists .chat-messages {
    height: calc(100% - 60px);
  }
  .role_pay_outer .chat_lists {
    max-height: 100%;
  }
  .chat_lists {
    height: 47vh;
  }
  .question_btn_list {
    padding: 0 10px;
  }
  .ourse_creator_outer .ourse_creator_inner {
    padding: 20px 15px 30px;
  }
  .custom_btn_style {
    max-width: 130px;
    height: 45px;
    font-size: 14px !important;
  }
  .ourse_creator_outer .card_row {
    padding: 0;
  }
  .ourse_creator_outer button {
    padding: 0 20px;
    font-size: 14px;
    line-height: 40px;
  }
  .card_box p,
  .inner_desc div {
    font-size: 16px !important;
    line-height: 30px !important;
  }
  .ourse_creator_outer .col_sec_outer {
    gap: 20px;
  }
  .top_sec_outer.topics_inner .card_row_outer {
    margin-bottom: 25px !important;
  }
  .ourse_creator_outer .inner_row_sec {
    padding: 0 0 30px;
  }
  .sub_topic_btn div {
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
  }
  .sub_topic_btn button {
    margin-bottom: 10px;
    width: 100%;
    height: 50px;
  }
  .chat_main {
    flex-wrap: wrap;
    gap: 0;
  }
  .btn_actions {
    padding: 20px;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  .btn_actions button {
    width: 100%;
    max-width: 100%;
  }
  .btn_actions button {
    max-width: 262px;
    height: 45px;
    padding: 12px 15px;
    font-size: 17px;
    margin: 0 0 14px;
  }
  .page_outer.lesson_planner_outer div {
    flex-wrap: wrap;
    width: 100%;
  }
  .page_outer.lesson_planner_outer div .bottom_btn button {
    width: 100%;
    margin: 10px 0 0 !important;
  }
  p#content {
    width: 100%;
  }
  p#content {
    white-space: normal;
    display: inline-block;
    max-width: 100%;
    word-wrap: break-word;
  }
  .grid_list {
    flex-wrap: wrap !important;
    width: 100%;
  }
  .check_box_outer {
    width: 100%;
    max-width: 100%;
    flex: unset;
  }
  .btn_wrp div {
    flex-wrap: wrap;
    width: 100% !important;
  }
  .btn_wrp div button {
    padding: 0 25px !important;
    width: auto !important;
  }
  .check_box_list {
    font-size: 14px;
    padding: 0 10px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .check_box_from {
    height: 50px;
    margin: 0 !important;
  }
  /* .page_outer.gist_page .inner_fild span {
	position: absolute;
	right: 0;
	top: 64px;
} */
}

.chat_main .avatar img {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}
.bot .avatar img {
  order: 2;
  width: 40px;
  margin-bottom: 10px;
  margin-left: 15px;
}

/* .pop-up-warning-container  {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
} */

.FlexItem .MuiButton-text {
  color: #fff;
  margin-right: 10px;
  border-radius: 50px;
  background-color: #bb0a1e;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  margin-left: 12px;
  text-transform: capitalize;
}
.FlexItem .MuiButton-text {
  padding: 6px 16px !important;
}
.FlexItem .MuiButton-text:hover {
  background-color: #dd0a1e;
  transition: 0.3s all;
}
.question_btn_list {
  display: flex;
  justify-content: space-between !important;
}
/* .pop-up-warning-container {
  position: relative;
  } */
.pop-up-warning-container div:nth-child(3) {
  width: 100%;
  display: flex;
  justify-content: center;
}
.pop-up-warning-container div:nth-child(3) > .custom_btn {
  max-width: 150px !important;
  max-height: 50px !important;
  font-size: 13px !important;
}
.pop-up-warning-container div:nth-child(1) {
  font-size: 18px;
  font-weight: 600px;
}
.pop-up-warning-container div:nth-child(1) {
  font-size: 31px;
  font-weight: 700 !important;
  color: #3f51b5;
  margin-bottom: 20px;
}
/* .MuiGrid-item .FlexItem {
  display: flex;
  justify-content: left;
  width: 100%;
} */

.lesson_planner_outer .bottom_btn .makeStyles-activityPopUp-12 {
  bottom: 4.8rem !important;
}
.ourse_creator_outer .top_sec_outer .inner_row_sec {
  justify-content: left;
  flex-direction: row;
}
.ourse_creator_outer .top_sec_outer .card_row {
  justify-content: left;
  flex-direction: row;
}
.MuiGrid-item .FlexRow {
  display: flex;
  flex-direction: column;
}

.reading-main-ac > div {
  text-align: left;
  padding: 50px 20px;
}
.pop-up-warning-container {
  width: calc(100%);
  max-width: 450px;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  position: fixed;
  left: 38%;
  top: 44%;
  background: #f2f1ff;
  padding: 20px;
  border-radius: 10px;
  flex-direction: column;
  height: 246px;
}
.bottom_btn > div div:nth-child(3) > div:nth-child(1) {
  margin-bottom: 10px;
}
.page_outer.role_pay_outer .inner_box .top_heading {
  text-align: left;
}
/* .pop-up-warning-container button.custom_btn {
  width: calc(100%);
  max-width: 130px !important;
} */
/* .custom_btn {
  max-width: 319.304px !important;
  height: 50.91px !important;
  font-size: 11.651px !important;
} */
.reading-main-ac > div:nth-child(3) > div:nth-child(3) button {
  font-size: 11px !important;
  height: 40px !important;
}
.debate_secs > div {
  text-align: left;
  margin-bottom: 20px;
  font-size: 18px;
}
.debate_secs {
  padding: 10px;
  border-radius: 10px;
  background-color: #ffffff;
}
.debate_outer {
  padding: 100px 40px;
}
.home_work_sec {
  padding: 100px 20px;
}
.home_work_sec_inner {
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
}
.top_heading_bottom div {
  text-align: left;
  margin-bottom: 20px;
  font-size: 16px;
}
.page_outer.gist_page .page_inner .label_tag {
  font-weight: 600 !important;
}
.customize_innerbox .label_tag {
  font-weight: 600 !important;
}
.cust_inner_box .top_title_cust {
  text-align: justify;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 20px;
}
.cust_inner_box_gap .page_inner .list_side,
.cust_inner_box_gap .page_inner .list_side input {
  font-size: 20px;
  display: flex;
  align-items: center;
}
.page_outer.role_pay_outer h4 {
  color: #404040;
}
.definationm_outer {
  margin-bottom: 100px;
}
.mui_in > div div:nth-child(2) > div {
  margin-bottom: 12px;
}
.quiz_inner {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 0px;
}
.quiz_inner > div {
  font-size: 18px;
}
.Real_main {
  padding: 20px 0px;
}
.Real_third {
  padding: 10px;
  display: flex;
}
.Real_main > div {
  background: #ffffff;
  padding: 20px 10px;
  margin-left: 20px;
  margin-right: 21px;
  border-radius: 10px;
}
.Real_main > div h5 {
  font-size: 30px;
  font-weight: 600;
}
.gist_page .inner_box > p {
  text-align: left;
  font-size: 18px;
  line-height: 29px;
}
.spinner_outer {
  position: relative;
}
.spinner_outer .spinner {
  position: fixed;
  top: 50%;
  left: 50%;
}
.para_case {
  text-align: justify;
  line-height: 29px !important;
}
.quiz_inner > div {
  text-align: justify;
}
/* .main_listings.input_box span {
  position: relative;
  top: -28px;
  left: 422px;
} */
span.check_close_fil svg {
  position: absolute;
  left: -6px;
  top: -28px;
}
.gist_page .inner_box > div {
  text-align: justify;
  line-height: 29px;
  font-size: 18px;
}
p.special_or {
  font-size: 22px;
  font-weight: 700 !important;
  text-transform: uppercase;
}
.Real_third {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.chat_cust .message.user .content {
  justify-content: left !important;
}
.suggestion-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  color: #333;
  font-size: 14px;
  z-index: 1000;
 cursor: pointer; /* Make sure it doesn't block typing */
  border-bottom: 1px solid #ddd;
}
.react-chatbot-kit-chat-container {
  width: 600px;
}
.react-chatbot-kit-chat-inner-container {
  width: 100%;
}

.WriteChat {
  padding-top: 0;
}
.WriteChat_inner_box {
  width: 100%;
  border: 1px solid black;
  border-radius: 20px;
  box-shadow: 0px 6px 6px 3px #000;
  background: #889bff;
  /* background: radial-gradient(#c1c1c1, transparent); */
}
.WriteChat_inner_box .top_heading h2 {
  text-align: center;
  font-size: 16px;

}
.WriteChat_inner_box .top_heading h2 span{
  padding-left: 25px;
  background-color: transparent;
}
.uniqe_editor {
  display: flex
  ;
  }
  .uniqe_editor>div {
  width: calc(100%);
  }
  .WriteChat_outer {
   width:calc(100%);
   max-width: 30%;
}
.jodit_Chat .jodit-container {
  min-height: 100% !important;
}
.jodit_Chat .jodit-container .jodit-workplace {
  min-height: 500px !important;
}
.jodit_Chat .jodit-container p {
  display: flex;
  text-align: left;
  height: 100%;
  /* line-height: 2px; */
  font-family: "Merriweather", serif !important; 
}
.jodit-wysiwyg p{
  display: flex;
  text-align: left;
  height: 100%;
  line-height: auto ;
  font-family: "Merriweather", serif !important; 
  overflow: auto;
}

.scramble_list {
  display: flex
;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.hint_input {
  position: absolute;
  top: -43px;
  display: flex;
  transform: translateY(70px);
  z-index: 9;

;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.material_scramble {
  position: relative;
  transform: translateY(-20px);
}
.material_scramble span {
  width: 16px;
}
.spl_page_in::before {
  content: '';
  position: absolute;
  top: -1rem;
  left: -1rem;
  display: block;
  background-color: #ffffff;
  background-image: linear-gradient(45deg, transparent 49%, #c3c0e1 50%, transparent 51%) !important;
  background-size: 5px 5px;
  right: -1rem;
  bottom: -1rem;
  z-index: -1;
  padding: 2rem 1rem;
  border: 1rem solid #3f51b5;
  border-image: repeating-linear-gradient(45deg, transparent, transparent 5px, #3f51b5 6px, #3f51b5 15px, transparent 16px, transparent 20px) 20 / 1rem;
  z-index: 0;
}
.hint_input input {
  border: 2px solid #3f51b5;
  border-radius: 5px;
  padding: 8px;
  }
  .spl_page_in {
  width: 100%;
  max-width: 340px;
  border: 1px solid black;
  padding: 25px 25px 90px 25px !important;
  background: #6a80ffa6;
  border-radius: 20px;
  }
  .scramble_list h4 {
  color: #3f51b5;
  font-size: 22px;
  font-family: cursive !important;
  }
  .scramble_list h3 {
  font-size: 23px;
  font-weight: 700;
  }
  .spl_page_in .question_option p {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700 !important;
  }
  .spl_page_in .question_mark span {
  font-size: 14px;
  color: #ffffff;
  background: #3f51b5;
  border-radius: 3px;
  padding: 2px;
  }
/* .question_list.scramble_list::after {
  content: '';
  position: absolute;
  border: 1px solid black;
  height: 218px;
  width: 28%;
  transform: translateY(94px);
  background: #93a0e4;
  border-radius: 20px;
} */

.material_scramble {
  position: relative;
  display: flex;
  gap: 5px;
;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.spl_page_in {
  width: 100%;
  max-width: 340px;
  border: 1px solid black;
  padding: 25px;
  background: #6a80ffa6;
  border-radius: 20px;
}
.box_outer_for_desg {
  display: flex
;
  justify-content: center;
}
 

.write_Chat_bot input {
  width: 80%;
  padding:10px;
  }
  .write_Chat_bot button {
  width: 20%;
  padding: 0;
  }
  .write_Chat_bot input[type="text"] {
  font-size: 13px;
  }
  .write_Chat_bot button svg{
  width:30px;
}
  .write_Chat_bot{
  height:45px;
  }
  .WriteChat_inner_box .chat-messages {
  height: calc(100% - 35px);
  width: 100%;
  }
  .WriteChat_inner_box .top_heading h2::after {
  top: 34px;
  }
  .WriteChat_inner_box .chat_lists .message {
    display: flex;
    justify-content: space-between;
    gap:0;
    grid-gap: 0;
}
.WriteChat_inner_box .chat_lists .message .avatar {
  width: calc(100%);
  max-width: 20%;
}
.WriteChat_inner_box .chat_lists .message .content{
  width: calc(100%) !important;
    max-width: 80%;
    font-size: 15px;
    padding: 10px;
    text-align: justify;
    font-family: "Merriweather", serif !important;
}
.WriteChat_inner_box .chat_lists .bot .avatar img {
  width: 25px !important;
  margin:0 !important;
}
.WriteChat_inner_box .chat_lists .message.user .avatar {
  width: 32px !important;
  height: 32px;
  border-radius: 10px;
  background: #e97514;
}
.WriteChat_inner_box {
  padding: 20px 15px;
  border: none;
}
.WriteChat_inner_box.inner_box .chat_lists {
  padding-top: 10px;
}

.WriteChat_inner_box.inner_box .top_heading h2::after {
  content: "";
    background: none;
    left: 0;
    right: 0;
    position: absolute;
    height: 36px;
    top: 0 !important;
    border-radius: 9px;
    z-index: 9;
    transform: translateY(-10px);
    width: 100%;
}
.WriteChat_inner_box .top_heading h2 {
  text-align: center;
    font-size: 16px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 800;
    font-family: cursive !important;
}
.activity_list_inner p {
  text-align: left;
  font-weight: 700 !important;
  font-size: 18px;
}
.top_heading p {
  font-size: 22px;
  font-weight: 700 !important;
}
.uniq_sub_check_btn button {
  margin-top: 23px;
  padding: 10px 30px;
  border-radius: 5px;
  border: none;
  background: #3f51b5;
  color: #fff;
  font-weight: 600;
}
.hint_input .checkCross {
  position: absolute;
  top: 7px;
  right: 0px;
  color:#ff0000;
}
.hint_input .checkIcon{
  position: absolute;
  top: 7px;
  right: 0px;
  color:#008000;
}
.desg_wrt_act-btn{
  display: flex;
}
.desg_wrt_act-btn button {
  color: #fff;
    background: #3f51b5;
    border: none;
    padding: 18px 40px;
    width: calc(100%);
    max-width: 88px;
    max-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-weight: 700;
  }
.chat_lists .chat-messages::-webkit-scrollbar {
  width: 5px; /* Width of the vertical scrollbar */
  height: 2px; /* Height of the horizontal scrollbar */
}

.chat_lists .chat-messages::-webkit-scrollbar-track {
  background: transparent; /* Background of the scrollbar track */
}

.chat_lists .chat-messages::-webkit-scrollbar-thumb {
  background: white; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners for thumb */
}

.keyboard_cust span {
  font-size: 17px;
  font-weight: 500;
}
.keyboard_cust {
  font-size: 20px;
  font-weight: 600 !important;
  text-align: left;
}
.MuiGrid-item .MuiBox-root div:nth-child(2) .popup li {
  background: #6478dd !important;
}
.genral_button>button {
  color: #fff;
  background-color: #3f51b5;
  padding: 20px 100px;
  font-size: 20px;
  font-family: 'Poppins' !important;
}
/* header {
  position: fixed !important;
  background: #576bd5 !important;
  padding: 0px 150px;
} */
.reading-main-ac>div {
  width: 210mm; /* A4 width in mm */
  /* height: 297mm;  */
  padding: 0;
  background: #fff;
  padding: 30px;
}
@media (max-width: 1024px) {
  .reading-main-ac>div {
      width: 100vw; /* Make it fit the viewport width */
      height: calc(100vw * 297 / 210); /* Maintain A4 aspect ratio */
      transform: scale(1); /* No scaling needed on mobile */
  }
}
.reading-main-ac {
  display: flex
;
  flex-direction: column;
  align-items: center;
}
body.scrolled header {
  position: fixed;
  background: #5061bd;
  transition: all 0.3s ease-in-out;
}
.mui_in > div div:nth-child(2) > div .popup h2 {
  color: #000 !important;
  font-size: 18px;
}
.course_lesson .makeStyles-ButtonGroup-149 {
  display: flex
;
  justify-content: space-between !important;
  width: 100% !important;
}
.button-box-outer .FlexItem {
  display: flex;
  justify-content: space-around;
}
.inner_desc p, .inner_desc>div {
  color: #565956 !important;
  font-size: 17px !important;
  font-weight: 600 !important;
}
@media screen and (max-width:600px){
  .activity_box>div {
    width: 100% !important;
    max-width: 100%;
}
.button-box-outer .FlexItem {
  display: flex;
  gap: 0px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.button-box-outer .FlexItem button {
  font-size: 12px;
  margin: 0;
  width: 100%;
  margin-bottom: 10px;
}
}
/*----new-css----*/

.lesson_plane_main .MuiBox-root-626 .FlexRow .MuiBox-root.MuiBox-root-668.FlexItem.Info {

  width: 100% !important;
}
.MuiBox-root-80{
  width: 100%;
}
.ourse_creator_outer .card_row_outer  p {
  font-weight: 400 !important;
}
.ourse_creator_outer .card_row_outer h6 {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 26px;
  text-align: left;
  color: #000;
}
.ourse_creator_outer .card_row_outer span.course_value {
  color: #565656;
  margin-right: 5px;
  font-weight: 500;
  font-size: 18px;
}
.ourse_creator_outer .card_row_outer span.heading_p {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: left;
  color: #000;
}
.topics_gener_main .card_row_outer  p {
  font-weight: 400 !important;
}
.topics_gener_main span.subtopic_gener {
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  text-align: left;
  color: #000;
}
.topics_gener_main .inner_desc{
  margin-bottom: 0;
}
.lesson_plane_main .FlexItem.Info {
  width: 100%;
}
.lesson_plane_main .content_outer{
  text-align: left;
}
.debate_outer .debate_secs div {
  cursor: pointer;
  background: #eff1fb;
  padding: 20px;
  border-radius: 10px;
  }

.reading-article {
  font-size: 20px;
  justify-content: center;
  text-align: justify;
  line-height: 1.5;
  margin-bottom: 50px;
}
.remove-btn{
  font-size: 14px;
  padding: 2px 10px;
  color: white;
  background-color: #3f51b5;
  border-radius: 5px;
  font-weight: 500;
}

.back-btn{
  font-size: 16px;
  padding: 4px 15px;
  color: white;
  background-color: #3f51b5;
  border-radius: 6px;
  font-weight: 600;
}